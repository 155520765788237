.profile {
    background-image: url("/src/assets/images/profile/profile.jpeg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 220px;
    width: 220px;

    border-radius: 9999px;
    filter: brightness(90%);

}

.profile.active {
    animation-name: pop;
    animation-duration: 0.3s;
    animation-timing-function: ease-in-out;
}
.profile.not-active {
    animation-name: pop;
    animation-duration: 0.3s;
    animation-timing-function: ease-in-out;
}

@keyframes pop {
    0% {
        transform: scale(1);
    }
    30% {
        transform: scale(1.1);
    }
    80% {
        transform: scale(0.9);
    }
    100% {
        transform: scale(1);
    }
}
