.circle-animation-container {
    top: 32px;

    width: 100%;
    height: 460px;

    display: flex;
    justify-content: center;
    position: relative;
}

.profile-in-animation {
    top: 74px;
    position: absolute;
}


.circle-animation {
    position: absolute;
    animation: circle-animation 1s linear infinite;
    will-change: transform;
}

.circle-animation:nth-child(1) {
    animation-duration: var(--base-animation-speed);
}

.circle-animation:nth-child(2) {
    animation-duration: calc(var(--base-animation-speed) * (4/3));
}

.circle-animation:nth-child(3) {
    animation-duration: calc(var(--base-animation-speed) * (5/3));
}

.circle-animation:nth-child(4) {
    animation-duration: calc(var(--base-animation-speed) * 2);
}

.circle-animation:nth-child(5) {
    animation-duration: calc(var(--base-animation-speed) * (7/3));
}

.circle-animation:nth-child(6) {
    animation-duration: calc(var(--base-animation-speed) * (8/3));
}

.circle-animation:nth-child(7) {
    animation-duration: calc(var(--base-animation-speed) * 3);
}

.circle-animation:nth-child(8) {
    animation-duration: calc(var(--base-animation-speed) * (10/3));
}

.circle-animation:nth-child(9) {
    animation-duration: calc(var(--base-animation-speed) * (11/3));
}

.circle-animation:nth-child(10) {
    animation-duration: calc(var(--base-animation-speed) * 4);
}

@keyframes circle-animation {
    100% {
        transform: rotate(1turn);
    }
}